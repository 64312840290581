/**
 * 混入对象，抽取Vue中公共的部分
 */
import { getPubkey, getCaptchaCode, getVerifyCode, login, logout, register, forgot } from '@/api/account'
import { setUserToken, removeAll } from '@/utils/auth'
import { JSEncrypt } from 'jsencrypt'
import Vue from 'vue'

export const userMixin = {
  data() {
    return {
      pubkey: '',
      pubkeyR: '',
      captchaCode: '',
      userLogin: {
        principal: null,
        credential: null,
        captchaCode: null,
        loginType: 2,
        plat: 2
      },
      userRegistry: {
        principal: null,
        verifyCode: null,
        credential: null,
        captchaCode: null,
        plat: 2
      },
      verifyCodeForm: {
        principal: null,
        channel: 2
      },
      userForgot: {
        principal: null,
        verifyCode: null,
        newCredential: null,
        captchaCode: null
      },
      loginDialog: false,
      registerDialog: false,
      dialogVisible: false,
      dialogVisible2: false,
      nickname: '', // 用户昵称
      phone: '', // 电话
      mobile: '', // 电话
      username: '', // 电话
      password: '', // 用户密码
      repassword: '', // 确认密码
      avatarurl: '', // 用户头像路径
      rcode: '', // 注册验证码
      isLoading: false, // 登录加载效果
      user: this.$user, // 登录用户对象
      code: '获取验证码',
      isBtn: false,
      imageUrl: require('assets/img/icon/avatar.png')
    }
  },
  methods: {
    fetchPubkey(channel) {
      getPubkey(channel).then(resp => {
        if (resp.code === 0) {
          this.pubkey = resp.data.pubkey
          this.pubkeyR = resp.data.r

          this.getCaptcha()
        } else {
          this.message = resp.msg
          this.showMessage = true
        }
      }).catch(error => {
        this.message = error.message
        this.showMessage = true
      })
    },
    getCaptcha() {
      getCaptchaCode().then(resp => {
        if (resp.code === 0) {
          this.captchaCode = resp.data
          this.dialogVisible = true
        } else {
          this.message = '获取图形验证码失败, 请重新刷新页面'
          this.showMessage = true
        }
      })
    },
    encryptPassword(password, pubkey, pubkeyR) {
      var encryptor = new JSEncrypt()
      encryptor.setPublicKey(pubkey)
      return encryptor.encrypt(pubkeyR + password)
    },
    registerVerifyCode() {
      if (this.userRegistry.principal === null || this.userRegistry.principal === '') {
        this.$message.success('请填写邮箱或手机号')
        return
      }

      this.verifyCodeForm.channel = 1
      this.verifyCodeForm.receiver = this.userRegistry.principal
      this.getVerifyCodeWrapper(this.verifyCodeForm)
    },
    loginVerifyCode() {
      if (this.userLogin.principal === null || this.userLogin.principal === '') {
        this.$message.success('请填写邮箱或手机号')
        return
      }

      this.verifyCodeForm.channel = 2
      this.verifyCodeForm.receiver = this.userLogin.principal
      this.getVerifyCodeWrapper(this.verifyCodeForm)
    },
    forgotVerifyCode() {
      if (this.userForgot.principal === null || this.userForgot.principal === '') {
        this.$message.success('请填写邮箱或手机号')
        return
      }

      this.verifyCodeForm.channel = 3
      this.verifyCodeForm.receiver = this.userRegistry.principal
      this.getVerifyCodeWrapper(this.verifyCodeForm)
    },
    getVerifyCodeWrapper(verifyCodeForm) {
      this.isBtn = true
      let time = 60
      const timeout = setInterval(() => {
        if (time !== 0) {
          time--
          this.code = time + 's后重新获取'
        } else {
          this.isBtn = false
          this.code = '重新获取验证码'
          clearTimeout(timeout)
        }
      }, 1000)
      getVerifyCode(verifyCodeForm).then(resp => {
        if (resp.code === 0) {
          this.$message.success('验证码已发送, 请注意查收')
        } else {
          this.$message.warning(resp.msg)
        }
      }).catch(error => {
        this.$message.error(error.message)
      })
    },
    // 昵称校验
    nickNameBlur() {
      if (this.nickname.length <= 0) {
        this.$message.warning('昵称不能为空')
      }
    },
    // 电话校验
    phoneBlur() {
      if (!(/^1[3456789]\d{9}$/.test(this.mobile))) {
        this.$message.warning('电话号码格式有误')
      }
    },
    loginByVerifyCode() {
      this.userLogin.loginType = 1
      this.loginBtn()
    },
    loginBtn() {
      if (this.userLogin.principal === '' || this.userLogin.principal === null) {
        this.$message.warning('手机号不能为空')
        return
      }
      if (this.userLogin.credential === '' || this.userLogin.credential === null) {
        this.$message.warning('短信验证码不能为空')
        return
      }
      if (this.userLogin.captchaCode === '' || this.userLogin.captchaCode === null) {
        this.$message.warning('图形验证码不能为空')
        return
      }

      const credential = this.userLogin.credential
      this.userLogin.credential = this.encryptPassword(credential, this.pubkey, this.pubkeyR)
      // 显示加载效果
      this.isLoading = true
      login(this.userLogin).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          const userInfo = respData.accountInfo
          const userToken = respData.accountToken
          // 保存授权信息到本地缓存
          setUserToken(userToken)
          this.$store.commit('UPDATE_USER_INFO', userInfo)

          // 刷新当前页面
          this.$router.go(0)
          this.userLogin = {
            principal: null,
            credential: null,
            captchaCode: null,
            loginType: 2,
            plat: 1
          }
        } else {
          // 登录失败
          this.$message.warning(resp.msg)
          this.userLogin.credential = credential
        }
      }).catch(error => {
        // 登录请求错误
        this.$message.error(error.message)
      }).finally(() => {
        this.isLoading = false
      })
    },
    registerBtn() {
      if (this.userRegistry.principal === '' || this.userRegistry.principal === null) {
        this.$message.warning('手机号不能为空')
        return
      }
      if (this.userRegistry.credential === '' || this.userRegistry.credential === null) {
        this.$message.warning('密码不能为空')
        return
      }
      if (this.userRegistry.verifyCode === '' || this.userRegistry.verifyCode === null) {
        this.$message.warning('短信验证码不能为空')
        return
      }
      if (this.userRegistry.captchaCode === '' || this.userRegistry.captchaCode === null) {
        this.$message.warning('图形验证码不能为空')
        return
      }

      this.userRegistry.credential = this.encryptPassword(this.userRegistry.credential, this.pubkey, this.pubkeyR)
      // 显示加载效果
      this.isLoading = true
      register(this.userRegistry).then(resp => {
        if (resp.code === 0) {
          // 关闭弹窗并刷新页面
          this.dialogVisible = false
          this.$message.info('帐号已注册, 稍后会转到登录页面')
          setInterval(() => {
            this.$router.push('/login')
          }, 3000)
        } else {
          this.$message.warning(resp.msg)
        }
      }).catch(error => {
        this.$message.error(error.message)
      }).finally(() => {
        this.isLoading = false
      })
    },
    forgotBtn() {
      if (this.userForgot.principal === '' || this.userForgot.principal === null) {
        this.$message.warning('手机号不能为空')
        return
      }
      if (this.userForgot.newCredential === '' || this.userForgot.newCredential === null) {
        this.$message.warning('密码不能为空')
        return
      }
      if (this.userForgot.verifyCode === '' || this.userForgot.verifyCode === null) {
        this.$message.warning('短信验证码不能为空')
        return
      }
      if (this.userForgot.captchaCode === '' || this.userForgot.captchaCode === null) {
        this.$message.warning('图形验证码不能为空')
        return
      }

      this.userForgot.newCredential = this.encryptPassword(this.userForgot.newCredential, this.pubkey, this.pubkeyR)
      // 显示加载效果
      this.isLoading = true
      forgot(this.userForgot).then(resp => {
        if (resp.code === 0) {
          // 关闭弹窗并刷新页面
          this.dialogVisible = false
          this.$message.info('密码已重置, 请返回登录页面登录帐号')
        } else {
          this.$message.warning(resp.msg)
        }
      }).catch(error => {
        this.$message.error(error.message)
      }).finally(() => {
        this.isLoading = false
        this.userForgot = {
          principal: null,
          verifyCode: null,
          newCredential: null,
          captchaCode: null
        }
      })
    },
    goToHome() {
      this.$router.push('/')
    },
    goToLogout() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logout().then(resp => {
          if (resp.code === 0) {
            Vue.$cookies.remove('token')
            this.$store.commit('USER_LOGOUT')
            removeAll()
          } else {
            this.$notify.error({
              title: '提示',
              message: resp.msg
            })
          }
        }).catch((e) => {
          console.log(e)
          this.$notify({
            message: '网络错误,请稍后再试...'
          })
        }).finally(() => {
          // 刷新当前页面
          this.$router.go(0)
          // this.$router.push('/')
          this.isLoading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
